import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { getHotspots } from "../../../services/Api_calling";
import Pagination from "../../../components/Pagination";
import { ToastContainer, toast } from "react-toastify";

const HotspotTable = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
    const limit = 15;

    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            const res = await getHotspots(currentPage, limit);
            if (res.success) {
                setData(res.data);
                setTotalNumberOfPages(res.totalNumberOfPages);
            }

            setLoading(false);
        };
        getData();
    }, [currentPage]);

    const renderFile = (data) => {
        switch (data.file_type) {
            case "image":
                return <div style={{
                    width: '150px',
                    margin: '0px auto'
                }}><img src={data.hotspot_file} height="100" width="100" className="img-fluid" alt="file" /></div>;
            case "video":
                return (
                    <div style={{
                        width: '150px',
                        margin: '0px auto'
                    }}>
                        <div className="row">
                            <div className="col-md-12">
                                <video controls>
                                    {/* <source src={data.hotspot_file} type={`video/${getFileExtension(data.hotspot_file)}`} /> */}
                                    <source src={data.hotspot_file} type="video/MP4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    // const getFileExtension = (filename) => {
    //     return filename.split('.').pop();
    // };   


    return (
        <>
            <ToastContainer position="top-center" />
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Link
                        to={"/add-hotspot"}
                        className="btn btn-primary btn-sm mr-1 mb-2 mt-1 float-right"
                    >
                        Create Hotspot
                    </Link>

                    {data.length ? (
                        <>
                            <table
                                id="dtBasicExample01"
                                className="table table-striped table-bordered table-sm mt-3 text-center"
                            >
                                <thead>
                                    <tr>
                                        <th className="th-sm">ID</th>
                                        <th className="th-sm">Hotspot Name</th>
                                        <th className="th-sm">File</th>
                                        <th className="th-sm">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((e, index) => (
                                        <tr key={index}>
                                            <td>{(currentPage - 1) * limit + index + 1}</td>
                                            <td>{e.hotspot_name}</td>
                                            <td>{renderFile(e)}</td>
                                            <td>
                                                <div className="text-center">
                                                    <Link to={"/edit-hostspot"} state={{ id: e.id, hotspotName: e.hotspot_name }} className="editIcon" style={{
                                                        margin: '0px auto'
                                                    }}>
                                                        <i className="bi bi-pencil-square text-black"></i>
                                                    </Link>

                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalNumberOfPages={totalNumberOfPages}
                            />
                        </>
                    ) : (
                        <h3 className="text-center">Data not found</h3>
                    )}
                </>
            )}
        </>
    );
};

export default HotspotTable;
