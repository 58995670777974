import React, { useState } from "react";
import { addUser } from "../../../services/Api_calling";
import { ToastContainer, toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AddUserForm = () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const userNameHandler = (e) => {
    setUserName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const phoneNumberHandler = (e) => {
    setPhoneNumber(e.target.value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (userName === "" || email === "" || phoneNumber === "") {
      toast.warning("All fields required");
      setLoading(false);
      return;
    }

    setLoading(true);

    const data = {
      user_name: userName,
      phone_number: phoneNumber,
      email: email,
    };

    const res = await addUser(data);

    if (res.success) {
      toast.success(res.message);
      setUserName("");
      setEmail("");
      setPhoneNumber("");
      navigate("/users-list");
    } else {
      toast.error(res.message);
    }

    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="topic_form">
        <form onSubmit={onSubmitHandler}>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="name">User Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      placeholder="Enter User Name"
                      value={userName}
                      onChange={userNameHandler}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="name">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="username"
                      name="username"
                      placeholder="Enter Email"
                      value={email}
                      onChange={emailHandler}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="name">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      placeholder="Enter Phone Number"
                      value={phoneNumber}
                      onChange={phoneNumberHandler}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-4">
                  {loading ? (
                    <TailSpin height={40} />
                  ) : (
                    <button className="btn btn-primary btn-block mt-1">
                      {loading ? <TailSpin height={20} /> : "Create user"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddUserForm;
