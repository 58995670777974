import React from "react";
import { NavLink, Link } from "react-router-dom";
export default function Sidebar() {
  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="dropdown profile-element left-profile">
              {/* <Link to="/home"> */}
              <img
                className=""
                src="Logo-frei.png"
                alt="logo"
                style={{
                  width: "139px",
                  height: "50px",
                  margin: "0px auto",
                  background: "white",
                  borderRadius: "4px",
                  padding: "4px",
                }}
              />
              {/* </Link> */}
              {/* <NavLink to="/dashboard"> */}
              {/* <span className="block m-t-xs font-bold profile-title font-italic">
                AJF
              </span> */}
              {/* </NavLink> */}
            </div>
            <div className="logo-element"></div>
          </li>
          {/* <li>
            <NavLink to="/dashboard">
              <i class="bi bi-grid-1x2"></i>
              <span className="nav-label">Dashboard</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/users-list">
              <i className="fa fa-users"></i>
              <span className="nav-label">Users</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/documents">
              <i className="fa fa-file"></i>
              <span className="nav-label">Documents</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/hotspots">
              <i class="fa fa-wifi"></i>
              <span className="nav-label">Hotspots</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/onetimeloginpasswordlist">
              <i class="fa fa-lock"></i>
              <span className="nav-label">One Time Login Password</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
