import React, { Component } from "react";
function Pagination({ currentPage, setCurrentPage, totalNumberOfPages }) {
  const pageNumbers = [];
  let i = 1;
  while (i <= totalNumberOfPages) {
    pageNumbers.push(i);
    i++;
  }

  return (
    <>
      <ul className="pagination">
        <li className="page-item">
          {currentPage !== 1 ? (
            <button
              className="paginetion-btn"
              style={{ color: "blue" }}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </button>
          ) : (
            <button
              className="paginetion-btn"
              disabled
              style={{ background: "#dddddd" }}
              onClick={() => setCurrentPage()}
            >
              Previous
            </button>
          )}
        </li>
        {pageNumbers.map((value, key) => (
          <li key={key} className="page-item">
            {currentPage === value ? (
              <button
                className="paginetion-btn"
                style={{ color: "blue" }}
                onClick={() => setCurrentPage(value)}
              >
                {value}
              </button>
            ) : (
              <button
                className="paginetion-btn"
                onClick={() => setCurrentPage(value)}
              >
                {value}
              </button>
            )}
          </li>
        ))}
        <li className="page-item">
          {currentPage !== totalNumberOfPages ? (
            <button
              className="paginetion-btn"
              style={{ color: "blue" }}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          ) : (
            <button
              className="paginetion-btn"
              disabled
              style={{ background: "#dddddd" }}
              onClick={() => setCurrentPage()}
            >
              Next
            </button>
          )}
        </li>
      </ul>
    </>
  );
}

export default Pagination;
