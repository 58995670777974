import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import { oneTimeLoginPasswordList, generateOneTimeLoginPassword } from "../../../services/Api_calling";
import Pagination from "../../../components/Pagination";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";

const PasswordTable = () => {
  const [loading, setLoading] = useState(false);
  const [passData, setPassData] = useState([]);
  const [copyPasscode, setCopyPasscode] = useState({
    id: 0,
    value: "",
    copied: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
  const limit = 15;

  const getData = async () => {
    setLoading(true);
    const res = await oneTimeLoginPasswordList(currentPage, limit);
    if (res.success) {
      setPassData(res.data);
      setTotalNumberOfPages(res.totalNumberOfPages);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  const handleGeneratePasswordButton = async () => {
    setLoading(true);
    const res = await generateOneTimeLoginPassword();

    if (res.success) {
      toast.success(res.message);
      getData();
    } else {
      toast.error(res.message)
    }
    setLoading(false);
  }

  return (
    <>
      <ToastContainer position="top-right" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <button
            onClick={handleGeneratePasswordButton}
            className="btn btn-primary btn-sm mr-1 mb-2 mt-1 float-right">
            Generate Password
          </button>
          {passData.length ? (
            <>
              <table
                id="dtBasicExample"
                className="table table-striped table-bordered table-sm mt-3 "
              >
                <thead>
                  <tr>
                    <th className="th-sm">SNO</th>
                    <th className="th-sm">Password</th>
                    <th className="th-sm">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {passData.map((p, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * limit + index + 1}</td>
                      <td>
                        <div className="row">
                          <div className="col-2">
                            <b>{p.password}</b>
                          </div>
                          <div className="col-6">
                            <CopyToClipboard
                              text={p.password}
                              onCopy={() => {
                                toast.success("Password copied to clipboard");
                                setCopyPasscode({
                                  id: p.id,
                                  value: p.password,
                                  copied: true,
                                });
                              }}
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                              >
                                <i
                                  class={`bi bi-copy ${p.id === copyPasscode.id &&
                                    copyPasscode.copied === true
                                    ? "click"
                                    : ""
                                    }`}
                                ></i>
                              </span>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className={`badge-${p.status == 0 ? "danger" : "primary"} rounded-3`}>
                          {p.status == 0 ? "USED" : "UNUSED"}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalNumberOfPages={totalNumberOfPages}
              />
            </>
          ) : (
            <h3 className="text-center">Data not found</h3>
          )}
        </>
      )}
    </>
  );
};

export default PasswordTable;
