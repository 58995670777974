import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { deleteDocument, getDocuments } from "../../../services/Api_calling";
import Pagination from "../../../components/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import pdfImage from "../../../images/pdf-image.png";

const DocumentTable = () => {
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
    const limit = 15;

    const getData = async () => {
        setLoading(true);
        try {
            const res = await getDocuments(currentPage, limit);
            if (res.success) {
                setFileData(res.data);
                setTotalNumberOfPages(res.totalNumberOfPages);
            } else {
                toast.error("Failed to fetch data");
            }
        } catch (error) {
            toast.error("An error occurred while fetching data");
        }
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, [currentPage]);

    const renderFile = (data) => {
        switch (data.file_type) {
            case "image":
                return <img src={data.document_file} height="100" width="100" alt="file" />;
            case "video":
                return (
                    <div style={{
                        width: '150px',
                        margin: '0px auto'
                    }}>
                        <div className="row">
                            <div className="col-md-12">
                                <video controls>
                                    {/* <source src={data.document_file} type={`video/${getFileExtension(data.document_file)}`} /> */}
                                    <source src={data.document_file} type="video/MP4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                );
            case "pdf":
                return (
                    <div>
                        <Link to={data.document_file} target="_blank" rel="noopener noreferrer" >
                            <div>
                                <img src={pdfImage} height="100" width="100" alt="pdf" />
                                <div>{data.file_name}</div>
                            </div>
                        </Link>
                    </div>)
            default:
                return null;
        }
    };

    // const getFileExtension = (filename) => {
    //     return filename.split('.').pop();
    // };


    const deleteFileHandler = async (id) => {
        const willDelete = await swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete this file?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        });

        if (willDelete) {
            const res = await deleteDocument(id);

            if (res.success) {
                getData();
                swal("Deleted!", "File has been deleted!", "success");
            } else {
                toast.error(res.message);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" />
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Link
                        to="/add-document"
                        className="btn btn-primary btn-sm mr-1 mb-2 mt-1 float-right"
                    >
                        Upload Document
                    </Link>
                    {fileData.length ? (
                        <>
                            <table
                                id="dtBasicExample"
                                className="table table-striped table-bordered table-sm mt-3 text-center"
                            >
                                <thead>
                                    <tr>
                                        <th className="th-sm">SNO</th>
                                        <th className="th-sm">File</th>
                                        <th className="th-sm">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fileData.map((data, index) => (
                                        <tr key={index}>
                                            <td>{(currentPage - 1) * limit + index + 1}</td>
                                            <td>{renderFile(data)}</td>
                                            <td>

                                                <button type="button" class="btn btn-danger" style={{
                                                    borderRadius: '100%'
                                                }}>
                                                    <i
                                                        class="bi bi-trash"
                                                        onClick={() => {
                                                            deleteFileHandler(data.id);
                                                        }}
                                                    ></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalNumberOfPages={totalNumberOfPages}
                            />
                        </>
                    ) : (
                        <h3 className="text-center">Data not found</h3>
                    )}
                </>
            )}
        </>
    );
};

export default DocumentTable;
