import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../states/AuthContext";
import { useContext } from "react";

const Header = (props) => {
  const { setLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const onLogoutHandler = (e) => {
    setLoggedIn(false);
    localStorage.clear();
    navigate("/", { replace: true });
  };

  const goBack = () => {
    window.history.back(); // This will navigate back in the browser history
  };

  return (
    <>
      <nav className="navbar navbar-static-top white-bg" role="navigation">
        {/* <div className="navbar-header"> */}
        {/* <button onClick={goBack}>
          <i class="fa fa-long-arrow-left"></i>
        </button> */}
        <ul className="nav navbar-top-links navbar-right">
          <li>
            <a
              onClick={() => {
                goBack();
              }}
            >
              <i className="fa fa-long-arrow-left"></i> Go Back
            </a>
          </li>
        </ul>

        <ul className="nav navbar-top-links navbar-right">
          <li>
            <a
              onClick={() => {
                onLogoutHandler();
              }}
            >
              <i className="fa fa-sign-out"></i> Log out
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
