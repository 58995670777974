import axios from "axios";
const baseUrl = process.env.REACT_APP_baseUrl;

const getHeader = () => {
  const authToken = localStorage.getItem("authToken");

  const headers = {
    Authorization: "Bearer " + authToken, //the token is a variable which holds the token
  };

  return headers;
};

const postRequest = async (path, data) => {
  let res = {
    success: false,
    message: "Something went wrong, please try again later",
  };
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}${path}`,
      data,
      headers: getHeader(),
    });
    res = response.data;
  } catch (err) {
    res.message = err.response?.data.message || err.message;
    return res;
  }
  return res;
};

const deleteRequest = async (path, data) => {
  let res = {
    success: false,
    message: "Something went wrong, please try again later",
  };

  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}${path}`,
      data,
      headers: getHeader(),
    });
    res = response.data;
  } catch (err) {
    console.log(err);
    res.message = err.response?.data.message || err.message;
    return res;
  }
  return res;
};

const putRequest = async (path, data) => {
  let res = {
    success: false,
    message: "Something went wrong, please try again later",
  };

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}${path}`,
      data,
      headers: getHeader(),
    });
    res = response.data;
  } catch (err) {
    res.message = err.response?.data.message || err.message;
    return res;
  }
  return res;
};

const getRequest = async (path) => {
  let res = {
    success: false,
    message: "Something went wrong, please try again later",
  };

  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}${path}`,
      // params: data,
      headers: getHeader(),
    });
    res = response.data;
  } catch (err) {
    res.message = err.response?.data.message || err.message;
    return res;
  }
  return res;
};

export const login = async (data) => {
  const path = "/admin/auth/login";
  return await postRequest(path, data);
};

export const getUsers = async (currentPage, limit) => {
  const path = `/admin/user/getUsers?page=${currentPage}&limit=${limit}`;
  return await getRequest(path);
};

export const addUser = async (data) => {
  const path = `/admin/user/createUser`;
  return await postRequest(path, data);
};

export const updateUserStatus = async (id) => {
  const path = `/admin/user/updateUserStatus/${id}`;
  return await putRequest(path);
};

export const getDocuments = async (page, limit) => {
  const path = `/admin/document/getDocuments?page=${page}&limit=${limit}`;
  return await getRequest(path);
}

export const uploadDocument = async (data) => {
  const path = `/admin/document/uploadDocument`;
  return await postRequest(path, data);
}

export const deleteDocument = async (id) => {
  const path = `/admin/document/deleteDocument/${id}`;
  return await deleteRequest(path);
}

export const createHotspot = async (data) => {
  const path = `/admin/hotspot/createHotspot`;
  return await postRequest(path, data);
}

export const getHotspots = async (page, limit) => {
  const path = `/admin/hotspot/getHotspots?page=${page}&limit=${limit}`;
  return await getRequest(path)
}

export const updateHotspot = async (id, data) => {
  const path = `/admin/hotspot/updateHotspot/${id}`;
  return await putRequest(path, data);
}

export const oneTimeLoginPasswordList = async (page) => {
  const path = `/admin/onetimeloginpassword/oneTimeLoginPasswords?page=${page}`;
  return await getRequest(path);
}

export const generateOneTimeLoginPassword = async () => {
  const path = `/admin/onetimeloginpassword/gererateOneTimeLoginPassword`;
  return await postRequest(path);
}