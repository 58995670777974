import React, { useState } from "react";
import Header from "../../../components/Header";
import PageTitle from "../../../components/Pagetitle";
import Nav from "../../../components/Nav";
import { TailSpin } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { uploadDocument } from "../../../services/Api_calling"


const AddDocument = () => {
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate()

    const fileHandler = (e) => {
        const seletedFile = e.target.files[0];
        if (seletedFile && (seletedFile.type.startsWith("image") || seletedFile.type.startsWith("video") || seletedFile.type.startsWith("application/pdf"))) {
            setFile(seletedFile);
        } else {
            toast.error("only image, video and pdf file allow ");
            setFile(null);
        }
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!file) {
            toast.warning("Please select file");
            setLoading(false);
            return;
        }

        const data = new FormData();

        if (file) {
            data.append("document", file);
        }

        const res = await uploadDocument(data);

        if (res.success) {
            toast.success(res.message);
            navigate("/documents")
        } else {
            toast.error(res.message);
        }
        setFile(null);
        setLoading(false);
    };


    return (
        <>
            <ToastContainer />
            <div className="nav_bg_color" id="wrapper">
                <Nav />
                <div id="page-wrapper" className="gray-bg">
                    <div className="row border-bottom">
                        <Header />
                    </div>
                    <div className="wrapper wrapper-content animated fadeInRight">
                        {/* Page title start */}
                        <PageTitle page_headline="Upload Document" />
                        {/* Page title end */}

                        <div className="row form_bg table_responsive white-bg">
                            <div className="col-lg-12">
                                <div className="topic_form">
                                    <form onSubmit={onSubmitHandler}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="name">Select file</label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                id="username"
                                                                name="username"
                                                                onChange={fileHandler}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mt-4">
                                                        {loading ? (
                                                            <TailSpin height={40} />
                                                        ) : (
                                                            <button type="submit" className="btn btn-primary btn-block mt-1">
                                                                {loading ? <TailSpin height={20} /> : "SUBMIT"}
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddDocument;
