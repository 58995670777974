import Login from "./screens/Login_screen";
import Dashboard from "./screens/dashboard/Dashboard";
import ErrorScreen from "./screens/Error_screen";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import { AuthContext } from "./states/AuthContext";
import { useContext } from "react";
import UsersList from "./screens/users/list/UsersList";
import AddUser from "./screens/users/add/AddUser";
import AddDocument from "./screens/document/add/AddDocument";
import DocumentList from "./screens/document/list/DocumentList";
import HotspotList from "./screens/hotspot/list/HotspotList";
import AddHotspot from "./screens/hotspot/add/AddHotspot";
import EditHotspot from "./screens/hotspot/add/EditHotspot";
import PasswordList from "./screens/one_time_login_password/list/PasswordList";

function App() {
  const { loggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={loggedIn ? <Navigate to="/users-list" /> : <Login />} />

      {/* <Route path="/dashboard" element={loggedIn ? <UsersList /> : <Login />} /> */}

      <Route
        path="/users-list"
        element={loggedIn ? <UsersList /> : <Navigate to="/" />}
      />

      <Route path="/add-user" element={loggedIn ? <AddUser /> : <Navigate to="/" />} />
      <Route path="/add-document" element={loggedIn ? <AddDocument /> : <Navigate to="/" />} />
      <Route path="/documents" element={loggedIn ? <DocumentList /> : <Navigate to="/" />} />
      <Route path="/hotspots" element={loggedIn ? <HotspotList /> : <Navigate to="/" />} />
      <Route path="/add-hotspot" element={loggedIn ? <AddHotspot /> : <Navigate to="/" />} />
      <Route path="/edit-hostspot" element={loggedIn ? <EditHotspot /> : <Navigate to="/" />} />
      <Route path="/onetimeloginpasswordlist" element={loggedIn ? <PasswordList /> : <Navigate to="/" />} />
      <Route path="*" element={<ErrorScreen />} />
    </Routes>
  );
}

export default App;
