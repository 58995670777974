import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { getUsers, updateUserStatus } from "../../../services/Api_calling";
import Pagination from "../../../components/Pagination";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";

const UsersTable = () => {
  const [loading, setLoading] = useState(false);
  const [usersData, setUserData] = useState([]);
  const [copyPasscode, setCopyPasscode] = useState({
    id: 0,
    value: "",
    copied: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
  const limit = 15;

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const res = await getUsers(currentPage, limit);
      if (res.success) {
        setUserData(res.data);
        setTotalNumberOfPages(res.totalNumberOfPages);
      }

      setLoading(false);
    };
    getData();
  }, [currentPage]);

  const userStatusHandler = async (userId, value) => {
    const previousData = [...usersData];

    const updatedUsersData = usersData.map((user) =>
      user.id === userId ? { ...user, status: value } : user
    );

    setUserData(updatedUsersData);
    const data = await updateUserStatus(userId);

    if (!data.success) {
      setUserData(previousData);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Link
            to={"/add-user"}
            className="btn btn-primary btn-sm mr-1 mb-2 mt-1 float-right"
          >
            Add User
          </Link>

          {usersData.length ? (
            <>
              <table
                id="dtBasicExample"
                className="table table-striped table-bordered table-sm mt-3 "
              >
                <thead>
                  <tr>
                    <th className="th-sm">SNO</th>
                    <th className="th-sm">User Name</th>
                    <th className="th-sm">Email</th>
                    <th className="th-sm">Phone Number</th>
                    <th className="th-sm">Pass Code</th>
                    <th className="th-sm">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData.map((user, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * limit + index + 1}</td>
                      <td>{user.user_name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone_number}</td>
                      <td>
                        <div className="row">
                          <div className="col-6">
                            <b>{user.pass_code}</b>
                          </div>
                          <div className="col-6">
                            <CopyToClipboard
                              text={user.pass_code}
                              onCopy={() => {
                                toast.success("Passcode copied to clipboard");
                                setCopyPasscode({
                                  id: user.id,
                                  value: user.pass_code,
                                  copied: true,
                                });
                              }}
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                              >
                                <i
                                  class={`bi bi-copy ${
                                    user.id === copyPasscode.id &&
                                    copyPasscode.copied === true
                                      ? "click"
                                      : ""
                                  }`}
                                ></i>
                              </span>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div className="dropdown">
                          <select
                            className={`form-select btn btn-${
                              user.status === 1 ? "primary" : "danger"
                            } dropdown-toggle`}
                            aria-label="Default select example"
                            value={user.status}
                            onChange={(e) =>
                              userStatusHandler(
                                user.id,
                                parseInt(e.target.value)
                              )
                            }
                          >
                            <option value={1} selected={user.status === 1}>
                              Active
                            </option>
                            <option value={0} selected={user.status === 0}>
                              In Active
                            </option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalNumberOfPages={totalNumberOfPages}
              />
            </>
          ) : (
            <h3 className="text-center">Data not found</h3>
          )}
        </>
      )}
    </>
  );
};

export default UsersTable;
